<template>
    <div class="pageBox">
        <div class="bannerBox"></div>
        <!-- <div class="topBox">
            <div class="title">
                <div> —— </div>
                <div class="titleCenter"> 服务工具 </div>
                <div> —— </div>
            </div>
            <div class="toolsType">
                <div class="toosBox" @click="entryTool(index)" v-for="(item, index) in toolsList" :key="index" :style="`background-color:${item.color};`">
                    <div>{{item.name}}</div>
                    <img :src="item.src" />
                </div>
            </div>
        </div> -->
        <div class="topBox">
            <div class="ysjBox" @click="entryTool(3)" >
                元 数 据 平 台
            </div>
        </div>
        <div class="topBox">
            <div class="title">
                <img src="../../assets/loginout/title-tips.png"/>
                <div class="titleCenter"> 平台接入 </div>
                <img src="../../assets/loginout/title-tips.png"/>
            </div>
            <div class="entryBox">
                <div class="entryItem">
                    <div class="entrytitle">接入案例</div>
                    <div :class="index>0?'entryBack':'entryLine'" @click="goEntery(item.id)" v-for="(item, index) in caseList" :key="index">
                        <img src="../../assets/loginout/entry.png"/>
                        <div class="text">
                            <div class="name">{{ item.title }}</div>
                            <div class="tipsCss">{{ item.summary }}</div>
                        </div>
                    </div>
                </div>
                <div class="entryItem">
                    <div class="entrytitle">接入规范</div>
                    <div :class="index>0?'entryBack':'entryLine'"  @click="goEntery(item.id)"  v-for="(item, index) in specificationlist" :key="index">
                        <img src="../../assets/loginout/entry.png"/>
                        <div class="text">
                            <div class="name">{{ item.title }}</div>
                            <div class="tipsCss">{{ item.summary }}</div>
                        </div>
                    </div>
                </div>
                <div class="entryItem">
                    <div class="entrytitle">接入流程</div>
                    <div :class="index>0?'entryBack':'entryLine'" @click="goEntery(item.id)"  v-for="(item, index) in flowList" :key="index">
                        <img src="../../assets/loginout/entry.png"/>
                        <div class="text">
                            <div class="name">{{ item.title }}</div>
                            <div class="tipsCss">{{ item.summary }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btnMore">
            <el-button size="large" type="primary" plain @click="openMore(0)">查看更多</el-button>
        </div> 
    </div>
</template>
<script>
import { OH_byType } from '@/api/openHome.js'
export default{
    data(){
        return{
            toolsList:[
                {name:'基因对比', src:require('../../assets/loginout/tools-01.png'), color:'#E7F8FF'},
                {name:'霉菌识别', src:require('../../assets/loginout/tools-02.png'), color:'#EAFBF3'},
                {name:'害虫识别', src:require('../../assets/loginout/tools-03.png'), color:'#E7F8FF'},
                {name:'元数据', src:require('../../assets/loginout/tools-04.png'), color:'#EAFBF3'}
            ],
            caseList:[],
            specificationlist:[],
            flowList:[],
        }
    },
    mounted(){
        this.GetohType()
    },
    methods:{
        GetohType(){
            OH_byType({page:1,size:3,types:60}).then(r=>{ //接入案例
               this.caseList =  r.data.list       
            }),
            OH_byType({page:1,size:3,types:61}).then(r=>{ //接入规范
                this.specificationlist = r.data.list  
            })
            OH_byType({page:1,size:3,types:62}).then(r=>{ //接入流程
               this.flowList =  r.data.list       
            })
        },
        entryTool(index){
            if(index==0){
                this.$router.push('/toolsText')
            }else if(index==1){
                this.$router.push('/toolsQuestion')
            }else if(index == 2){
                this.$router.push('/toolsBug')
            }else{
                this.$router.push('/toolsData')
            }
        },
        goEntery(id){
            this.$router.push({
                query:{'id':id},
                path:'/openDetilsTool'
            })
        },
        openMore(type){
            this.$router.push({
                query:{'type':type},
                path:'/openMoreFile3'
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.ysjBox{
    width: 100%;
    height: 150px;
    background-image: url('../../assets/loginout/ysj.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    line-height: 150px;
    color: white;
    font-size: 50px;
    cursor: pointer;
}
.pageBox{
    width:100%;
    padding:0px 0px 20px 0px;
    background-color:white;
}
.bannerBox{
    width:100%;
    height:280px;
    background-image: url('../../assets/loginout/应用指南.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.topBox{
    width:1400px;
    margin: 0 auto;
    margin-top: 80px;
    .title{
        display:flex;
        justify-content:center;
        align-items:center;
        height:100px;

        color:rgb(200, 195, 195);
        .titleCenter{
            margin:0px 20px;
            font-size:20px;
            color:#333;
            font-weight: 600;
        }
    }
    
}
.toolsType{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .toosBox{
        width: 300px;
        height: 320px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 20px;
        border-radius: 10px;
        font-weight: 600;
        img{
            width: 160px;
            height: 200px;
            object-fit: contain;
        }
    }
}
.entryBox{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .entryItem{
        width: calc(33% - 30px);
        .entrytitle{
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 18px;
            font-weight: 600;
        }
        .entryLine{
            cursor: pointer;
            margin-bottom: 20px;
            width: 100%;
            height: 110px;
            box-shadow: 0px 0px 5px gray;
            border-bottom: 1px solid blue;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .entryBack{
            margin-bottom: 20px;
            cursor: pointer;
            width: 100%;
            height: 110px;
            border:1px solid rgb(185, 185, 185);
            display: flex;
            justify-content: center;
            align-items: center;
        }
        img{
            width: 40px;
            height: 40px;
            margin-right: 30px;
            object-fit: contain;
        }
        .text{
            width: calc(100% - 120px);
            font-size: 14px;
            color: #333;
        }
        .name{
           font-size: 16px;
           margin-top: 10px;
           margin-bottom: 10px;
           font-weight: 600; 
           overflow: hidden;
           text-overflow: ellipsis;
           white-space: nowrap;
        }
        .tipsCss{
           overflow: hidden;
           text-overflow: ellipsis;
           white-space: nowrap;
        }
    }
}
.btnMore{
    width: 100%;
    margin-top: 20px;
    text-align: center;
}
</style>